var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content",class:{ 'wrap__content--preloader': !_vm.application || !_vm._getFishes }},[(!_vm.application || !_vm._getFishes)?_c('v-preloader',{attrs:{"message":_vm.errorMessage}}):_vm._e(),(_vm.application && _vm._getFishes)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['GrownManagement', 'TradeManagementMain']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_c('div',{staticClass:"card__content-header"},[_c('div',{staticClass:"content__title"},[_c('div',{staticClass:"content__page--back"},[_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{attrs:{"width":"21","height":"21","viewBox":"0 0 21 21","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M13.125 5.25l-5.25 5.25 5.25 5.25","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_vm._m(0)])]),_c('div',{staticClass:"card__content--body"},[_c('div',{staticClass:"form__line"},[_vm._m(1),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
													_vm.errorMessage.messages && _vm.errorMessage.messages.catch_at
														? 'input--error'
														: '',
												]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"catch_at","disabled":"","placeholder":"Дата улова"},domProps:{"value":_vm._f("formatDate")(_vm.application.catch_at)}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.catch_at)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.catch_at),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_vm._m(2),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
													_vm.errorMessage.messages && _vm.errorMessage.messages.reference
														? 'input--error'
														: '',
												]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"reference","disabled":"","placeholder":"Номер"},domProps:{"value":_vm.application.reference}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.reference)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.reference),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.composition_catch_fish')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-7"},_vm._l((_vm.formList.fish),function(fish,fishIndex){return _c('div',{key:fishIndex,staticClass:"quota-fish__line"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-xl-10"},[_c('div',{staticClass:"quota-fish__item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-xl-7"},[_c('div',{staticClass:"form__line--input"},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","disabled":""},domProps:{"value":fish.name}})])]),_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(fish.value),expression:"fish.value"}],staticClass:"input-linck",attrs:{"type":"number","step":".01","min":"0","disabled":"","placeholder":"0"},domProps:{"value":(fish.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(fish, "value", $event.target.value)}}})])])])])])])])])}),0)])])])])])])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title--element"},[_c('div',{staticClass:"content__title--text"},[_vm._v("Журнал учета выращенной рыбы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Дата забоя")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Номер справки о происхождении")])])
}]

export { render, staticRenderFns }